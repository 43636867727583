import clsx from 'clsx'

const TypographyDisplay = ({
	children = <></>,
	size = 'medium',
	isBold = undefined,
	additionalClasses = '',
	...props
}: {
	children: React.ReactNode
	size?: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge'
	isBold?: true | false | undefined
	additionalClasses?: string
}) => {
	return (
		<h1
			className={clsx(
				size === 'xLarge' &&
					!isBold &&
					'font-rapidSpecial text-displayXLarge uppercase',
				size === 'xLarge' &&
					isBold &&
					'font-rapidSpecial text-displayXLargeBold uppercase',
				size === 'large' &&
					isBold &&
					'font-rapidSpecial text-displayLargeBold uppercase',
				size === 'large' &&
					!isBold &&
					'font-rapidSpecial text-displayLarge uppercase',
				size === 'medium' &&
					isBold &&
					'font-rapid text-displayMediumBold uppercase',
				size === 'medium' &&
					!isBold &&
					'font-rapid text-displayMedium uppercase',
				size === 'small' &&
					isBold &&
					'font-rapid text-displaySmallBold uppercase',
				size === 'small' && !isBold && 'font-rapid text-displaySmall uppercase',
				size === 'xSmall' &&
					isBold &&
					'font-rapid text-displayXSmallBold uppercase',
				size === 'xSmall' &&
					!isBold &&
					'font-rapid text-displayXSmall uppercase',
				`${additionalClasses}`,
			)}
			{...props}
		>
			{children}
		</h1>
	)
}

export default TypographyDisplay
